<template>
    <button v-if="isSet" class="btn btn-link" type="button" @click="onClick">
        <i class="bi-x-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterItemResetBtn",
    props: {
        filterId: Number,
        viewId: String,
        filter: Object
    },
    computed: {
        isSet() {
            return this.filter.value !== undefined
        }
    },
    methods: {
        onClick() {
            this.$store.commit(this.viewId + '/setFilterValue', {
                id: this.filterId,
                value: undefined
            })

            // When resetting the IS and IS NOT SET options, as they have no value fields, the option menu must
            // also be reset.
            if (
                this.filter.condition === 'is set'
                || this.filter.condition === 'is not set'
            ) {
                this.filter.condition = ''

            } else if (
                this.filter.type === 'relationshipOneToMany'
                && (
                    this.filter.condition === 'one is set'
                    || this.filter.condition === 'one is not set'
                )
            ) {
                this.filter.condition = ''

            } else if (
                this.filter.type === 'relationshipManyToMany'
                && (
                    this.filter.condition === 'many is set'
                    || this.filter.condition === 'many is not set'
                )
            ) {
                this.filter.condition = ''
            }
        }
    }
}
</script>

<style scoped>

</style>