<template>
    <span class="dropdown">
        <span v-if="options.length === 1">
            {{ filter.conditionText }}
        </span>

        <button v-if="options.length > 1" class="btn btn-light ms-1" data-bs-toggle="dropdown" type="button">
            {{ filter.conditionText }}
        </button>

        <ul class="dropdown-menu">
            <li v-for="(option, index) in options" :key="option.id" @click="setOption(index)">
                <a :class="{'active': option.text === filter.conditionText}"
                   class="dropdown-item">{{ option.text }}</a>
            </li>
        </ul>
    </span>
</template>

<script>
export default {
    name: "SearchFilterColumnOptions",
    props: {
        viewId: String,
        filter: Object,
        columns: Array
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        column() {
            return this.columns.find(o => o.columnName === this.filter.columnName)
        },
        options() {
            let options = []

            if (this.column) {
                options = this.$store.getters['advancedFilters/typesOptions'](this.column.type)
            }

            this.updateFilterData(options)

            return options
        }
    },
    created() {
        // The IS SET and IS NOT SET conditions also set their values because they do not have fields for storing values.
        // This value must be unset when returning to the advanced filters.
return
        if (
            (this.filter.condition === 'is set' || this.filter.condition === 'is not set')
            && this.filter.value === undefined
        ) {
            this.filter.condition = ''
        }

        if (
            this.filter.type === 'relationshipOneToMany'
            && (this.filter.condition === 'one is set' || this.filter.condition === 'one is not set')
            && this.filter.value === undefined
        ) {
            this.filter.condition = ''
        }

        if (
            this.filter.type === 'relationshipManyToMany'
            && (this.filter.condition === 'many is set' || this.filter.condition === 'many is not set')
            && this.filter.value === undefined
        ) {
            this.filter.condition = ''
        }
    },
    methods: {
        updateFilterData(options) {
            // todo - There seems to be a lot of duplication in the scripts below and in setOption.
            //        Can I simplify it?

            let option
            if (this.filter.condition) {
                option = options.find(o => o.condition === this.filter.condition)
            } else {
                option = options[0]
                this.filter.condition = option.condition
                this.filter.conditionText = option.text
            }

            this.filter.type = option.type
        },
        setOption(i) {
            let option = this.options[i]
            this.filter.condition = option.condition
            this.filter.conditionText = option.text
            this.filter.type = option.type

            this.$store.dispatch(this.viewId + '/updateFilter', {
                filterId: this.filter.id,
                filterData: this.filter
            })
                .then(() => {
                    if (
                        this.filter.condition === 'is set'
                        || this.filter.condition === 'is not set'
                    ) {
                        this.filter.value = this.filter.condition

                    } else if (option.type === 'relationshipOneToMany') {
                        if (option.condition === 'one is set') {
                            this.filter.value = 'IS SET'
                        } else if (option.condition === 'one is not set') {
                            this.filter.value = 'IS NOT SET'
                        }

                    } else if (option.type === 'relationshipManyToMany') {
                        if (option.condition === 'many is set') {
                            this.filter.value = 'IS SET'
                        } else if (option.condition === 'many is not set') {
                            this.filter.value = 'IS NOT SET'
                        }
                    }

                    // This triggers /store/plugins/loadView.js to call view module's /scheduleLoad
                    this.$store.commit(this.viewId + '/setFilterValue', {
                        id: this.filter.id,
                        value: this.filter.value
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>