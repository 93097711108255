export default {
    namespaced: true,
    state: {
        promises: {},
    },
    getters: {
        typesOptions: (state) => (type) => {
            let options = []
            switch (type) {
                
                case 'checkbox':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        }
                        /*
                         {
                         condition: 'is checked',
                         text: 'is checked'
                         },
                         {
                         condition: 'is not checked',
                         text: 'is not checked'
                         }
                         */
                    ]
                    break
                
                case 'color':
                case 'range':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'custom':
                case 'customscript':
                    break
                
                case 'date':
                case 'dateDate':
                case 'datetime-local':
                case 'time':
                    options = [
                        {
                            condition: 'date is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'date is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'date is after',
                            text: 'is after',
                            type: type
                        },
                        {
                            condition: 'date is before',
                            text: 'is before',
                            type: type
                        },
                        /*{
                            condition: 'date is in the last',
                            text: 'is in the last',
                            type: type
                        },
                        {
                            condition: 'date is not in the last',
                            text: 'is not in the last',
                            type: type
                        }/*,
                        {
                            condition: 'date is in the range',
                            text: 'is in the range',
                            type: 'dateRange'
                        }*/
                    ]
                    break
                
                case 'file':
                    options = [
                        {
                            condition: 'has file',
                            //text: 'has file'
                            text: 'exists'
                        },
                        {
                            condition: 'does not have file',
                            //text: 'does not have file'
                            text: 'does not exist'
                        }
                    ]
                    break
                
                case 'hidden':
                    break
                
                case 'internalLink':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'number':
                case 'currency':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'is less than',
                            text: 'is less than',
                            type: type
                        },
                        {
                            condition: 'is greater than',
                            text: 'is greater than',
                            type: type
                        }
                    ]
                    break
                
                // Password cannot use "is set" and "is not set" because it has not top option (e.g. "Select...") and
                // so there's no means to unset the menu.
                case 'password':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        }
                    ]
                    break
                
                case 'relationshipOneToMany':
                    options = [
                        {
                            condition: 'is associated to',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not associated to',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'one is set',
                            text: 'is set',
                            type: type
                        },
                        {
                            condition: 'one is not set',
                            text: 'is not set',
                            type: type
                        }
                    ]
                    break
                
                case 'relationshipManyToMany':
                    options = [
                        {
                            condition: 'is associated to many',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not associated to many',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'many is set',
                            text: 'is set',
                            type: type
                        },
                        {
                            condition: 'many is not set',
                            text: 'is not set',
                            type: type
                        }
                    ]
                    break
                
                case 'select':
                    options = [
                        {
                            condition: 'select is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'select is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'email':
                case 'tel':
                case 'text':
                case 'textarea':
                case 'url':
                    options = [
                        {
                            condition: 'contains',
                            text: 'contains',
                            type: type
                        },
                        {
                            condition: 'does not contain',
                            text: 'does not contain',
                            type: type
                        },
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'starts with',
                            text: 'starts with',
                            type: type
                        },
                        {
                            condition: 'does not start with',
                            text: 'does not start with',
                            type: type
                        },
                        {
                            condition: 'ends with',
                            text: 'ends with',
                            type: type
                        },
                        {
                            condition: 'does not end with',
                            text: 'does not end with',
                            type: type
                        },
                        {
                            condition: 'is set',
                            text: 'is set',
                            type: type
                        },
                        {
                            condition: 'is not set',
                            text: 'is not set',
                            type: type
                        }
                    ]
                    break
                
                case 'texteditor':
                    options = [
                        {
                            condition: 'contains',
                            text: 'contains',
                            type: type
                        },
                        {
                            condition: 'does not contain',
                            text: 'does not contain',
                            type: type
                        },
                        {
                            condition: 'is set',
                            text: 'is set',
                            type: type
                        },
                        {
                            condition: 'is not set',
                            text: 'is not set',
                            type: type
                        }
                    ]
                    break
            }
            
            return options
        }
    }
}