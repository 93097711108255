<template>
    <form class="form form-inline search-filters" role="search" @submit.prevent>
        <div class="list-group list-group-flush">
            <SearchFilterItem v-for="filter in filters" :key="filter.id" :filter="filter" :view-id="viewId"/>
        </div>

        <!--<pre>{{ filters }}</pre>-->
    </form>
</template>

<script>
import SearchFilterItem from './SearchFilterItem'

export default {
    name: "SearchFilters",
    components: {
        SearchFilterItem
    },
    props: {
        viewId: String
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        }
    },
    created() {
        this.$store.dispatch(this.viewId + '/initAdvancedFilters')
    }
}
</script>

<style scoped>

</style>