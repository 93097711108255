<template>
    <div class="dropdown d-inline-block">
        <button class="btn btn-light" data-bs-toggle="dropdown" type="button">
            {{ columnTitle }}
        </button>

        <ul class="dropdown-menu pt-0">
            <template v-for="fieldset in fieldsets">
                <li class="dropdown-item disabled" :class="{'pt-3': fieldset.legend !== '&nbsp;'}" v-html="fieldset.legend"/>
                <li v-for="column in getColumns(fieldset.id)" :key="column.id" @click="setColumn(column.columnName)">
                    <a :class="{'active': column.columnName === columnName}" class="dropdown-item">
                        {{ column.title }}
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SearchFilterColumnMenu",
    props: {
        filter: Object,
        columns: Array,
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        columnName() {
            return this.filter.columnName
        },
        columnTitle() {
            return this.columns.find(o => o.columnName === this.columnName)?.title
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fieldsets() {
            const fieldsets = this.$store.state.fieldsets.items.filter(o => o.componentId === this.componentId)

            fieldsets.push({
                id: 0,
                legend: '&nbsp;',
            })

            return fieldsets
        }
    },
    methods: {
        getColumns(fieldsetId) {
            return this.columns.filter(o => o.fieldsetId === fieldsetId)
        },
        setColumn(columnName) {
            // Unset the previously set value, otherwise it might conflict with the new field's props.
            // For example, the relationshipOneToMany field's component expects a String value.
            this.filter.value = undefined

            this.filter.columnName = columnName
            this.filter.condition = false

            let columns = this.columns.filter((column) => {
                return column.columnName === columnName
            })

            if (columns) {
                let column = columns[0]
                let options = this.$store.getters['advancedFilters/typesOptions'](column.type)

                this.filter.condition = options[0].condition
                this.filter.conditionText = options[0].text
            }

            this.$store.dispatch(this.viewId + '/updateFilter', {
                filterId: this.filter.id,
                filterData: this.filter
            })
        }
    }
}
</script>

<style scoped>

</style>