var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "dropdown" }, [
    _vm.options.length === 1
      ? _c("span", [
          _vm._v("\n        " + _vm._s(_vm.filter.conditionText) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.length > 1
      ? _c(
          "button",
          {
            staticClass: "btn btn-light ms-1",
            attrs: { "data-bs-toggle": "dropdown", type: "button" },
          },
          [_vm._v("\n        " + _vm._s(_vm.filter.conditionText) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu" },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "li",
          {
            key: option.id,
            on: {
              click: function ($event) {
                return _vm.setOption(index)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { active: option.text === _vm.filter.conditionText },
              },
              [_vm._v(_vm._s(option.text))]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }