<template>
    <button class="btn btn-light m-3" type="button" @click="addFilter">
        <i class="bi-plus-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterAddFilterBtn",
    props: {
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        }
    },
    methods: {
        addFilter() {
            this.$store.dispatch(this.viewId + '/addFilter', {
                filterData: {
                    columnName: this.tableName + 'ID',
                    condition: false,
                    conditionText: 'is',
                    type: 'text'
                }
            })
        }
    }
}
</script>

<style scoped>

</style>