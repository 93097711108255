<template>
    <div class="list-group-item d-flex align-items-center">
        <SearchFilterColumnMenu v-if="columns" :columns="columns" :filter="filter" :view-id="viewId"/>

        <SearchFilterColumnOptions v-if="columns" :view-id="viewId" :columns="columns" :filter="filter"/>

        <SearchFilterField v-if="columns" :filter="filter" :view-id="viewId"/>

        <button class="ms-1 btn btn-light component-filters-item-remove-btn" type="button" @click="removeFilter">
            <small class="bi-trash3"/>
        </button>
    </div>
</template>

<script>
import SearchFilterColumnMenu from './SearchFilterColumnMenu'
import SearchFilterColumnOptions from './SearchFilterColumnOptions'
import SearchFilterField from './SearchFilterField'

export default {
    name: "SearchFilterItem",
    components: {
        SearchFilterColumnMenu,
        SearchFilterColumnOptions,
        SearchFilterField
    },
    props: {
        viewId: String,
        filter: Object
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fields() {
            return this.$store.getters['componentStructure/get'](this.componentId)
        },
        firstFieldsetId() {
            return this.$store.state.fieldsets.items.find(o => o.componentId === this.componentId).id
        },
        columns() {
            const idColumn = {
                fieldsetId: this.firstFieldsetId,
                columnName: this.tableName + 'ID',
                title: 'ID',
                type: 'number'
            }

            let activeDataTypes = [
                'checkbox',
                'color',
                'currency',
                'date',
                'dateDate',
                'datetime-local',
                'email',
                'internalLink',
                //'file',
                'number',
                'password',
                'range',
                'relationshipOneToMany',
                'relationshipManyToMany',
                'select',
                'tel',
                'text',
                'textarea',
                'texteditor',
                'time',
                'url',
            ]

            const columns = this.fields.filter(o => {
                return activeDataTypes.indexOf(o.type) >= 0
            })

            return [idColumn].concat(columns)
        }
    },
    methods: {
        removeFilter() {
            this.$store.dispatch(this.viewId + '/deleteFilter', {
                filterId: this.filter.id
            })
        }
    }
}
</script>

<style scoped>

</style>