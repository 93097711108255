<template>
    <div class="modal-dialog modal-lg modal-fullscreen-sm-down">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Search</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <SearchField :view-id="viewId" :hide-modal="hideModal" class="mb-3 text-center"/>

                <SearchFilters :view-id="viewId"/>

                <SearchFilterAddFilterBtn :view-id="viewId"/>
            </div>
        </div>
    </div>
</template>

<script>
import SearchField from '../../common/search-filters/SearchField'
import SearchFilters from '../../common/search-filters/SearchFilters'
import SearchFilterAddFilterBtn from '../../common/search-filters/SearchFilterAddFilterBtn'

export default {
    name: "SearchModal",
    components: {
        SearchField,
        SearchFilters,
        SearchFilterAddFilterBtn,
    },
    props: {
        options: Object,
    },
    data() {
        return {
            viewId: this.options.viewId,
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>